import Link from 'next/link';
import { ArrowBack, ErrorOutline } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';

export default function NotFoundPage() {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Paper sx={{ p: 3 }} elevation={3}>
        <Typography color="text.primary" variant="h4" fontWeight="bold" mb={1}>
          Água Amigão | Web
        </Typography>
        <Typography
          sx={{ color: orange[500] }}
          display="flex"
          alignItems="center"
          my={3}
        >
          <ErrorOutline sx={{ marginRight: 1 }} />
          Página não encontrada!
        </Typography>
        <Typography>
          A página que você está tentando acessar não foi encontrada.
        </Typography>
        <Box justifyContent="center" mt={3}>
          <Link href="/" passHref>
            <Button fullWidth size="large" startIcon={<ArrowBack />}>
              Voltar para o início
            </Button>
          </Link>
        </Box>
      </Paper>
    </Box>
  );
}
